<script>
	import { _, locale } from 'svelte-i18n';
	import LoginProvider from '../LoginProvider.svelte';
	import VerifyEmail from '../VerifyEmail.svelte';
	import MailIcon from '../icon/MailIcon.svelte';
	import LoginPreferred from './LoginPreferred.svelte';
	import LoginManaged from './LoginManaged.svelte';

	export let login_hint = {};
	export let continueWithProvider = () => {};
	export let contactLoginSuccess = () => {};
	export let handleContactError = () => {};
	export let handleManagedEmailSuccess = () => {};
	export let continueWithEthereumExtension = () => {};

	const isManaged = !!login_hint.managed;
	const isManagedEmail = !!login_hint.managed?.email;
	const isPreferred = !!login_hint.preferred;
</script>

{#if isManaged}
	{#if isManagedEmail}
		{@const managedEmail = login_hint.managed.email}
		<!-- TBD REFACTOR - USE LOGINMANAGED COMPONENT -->
		<div class="relative h-auto btn-border rounded-md hover-none px-4 pt-4">
			<button class="mb-2 w-full flex items-center justify-start">
				<MailIcon size="lg" />
				<div
					class="flex flex-col {$locale && $locale.startsWith('ar')
						? 'text-right mr-4'
						: 'text-left ml-4'}"
				>
					<span>
						{$_('Continue with Email')}
					</span>
					<span>{managedEmail}</span>
				</div>
			</button>

			<div class="pb-4 pt-1">
				<VerifyEmail
					email={managedEmail}
					login
					disabled
					accountSelected="managed"
					accountToUse="managed"
					on:success={contactLoginSuccess}
					on:error={handleContactError}
				/>
			</div>
		</div>
	{:else}
		<LoginManaged
			accountSelected="managed"
			accountToUse="managed"
			accounts={[login_hint.managed]}
			showUseDifferentManaged={false}
			{continueWithProvider}
			{contactLoginSuccess}
			{handleContactError}
			{handleManagedEmailSuccess}
		/>
	{/if}
{:else if isPreferred}
	<LoginPreferred
		accountSelected="personal"
		accounts={[login_hint.preferred]}
		showOtherWaysToLogin={false}
		{contactLoginSuccess}
		{handleContactError}
		{continueWithEthereumExtension}
		{continueWithProvider}
	/>
{:else if login_hint.email}
	<div class="relative h-auto btn-border rounded-md hover-none px-4 pt-4">
		{#if login_hint.provider?.slug}
			<LoginProvider
				login={true}
				on:click={() =>
					continueWithProvider({
						slug: login_hint.provider.slug,
						body: {
							login_hint: login_hint.email,
							accountSelected: 'unknown'
						}
					})}
				provider={{
					slug: login_hint.provider.slug,
					user_name: login_hint.email
				}}
				prefix="Continue with"
			/>

			<span class="block text-lg text-center my-3 uppercase">{$_('Or')}</span>
		{/if}

		<button class="mb-2 w-full flex items-center justify-start">
			<MailIcon size="lg" />
			<div
				class="flex flex-col {$locale && $locale.startsWith('ar')
					? 'text-right mr-4'
					: 'text-left ml-4'}"
			>
				<span>
					{$_('Continue with Email')}
				</span>
				<span>{login_hint.email}</span>
			</div>
		</button>

		<div class="pb-4 pt-1">
			<VerifyEmail
				email={login_hint.email}
				login
				disabled
				accountSelected="unknown"
				on:success={contactLoginSuccess}
				on:error={handleContactError}
			/>
		</div>
	</div>
{/if}
