<script>
	import { _ } from 'svelte-i18n';
	import { data } from '../../stores.js';
	import LoginRequiredModal from '../modal/LoginRequiredModal.svelte';
	import HelpButton from '../button/HelpButton.svelte';

	export let authnWithManaged = false;
	export let usePersonalAccount = false;
	export let contactLoginSuccess = () => {};
	export let handleContactError = () => {};
	export let continueWithEthereumExtension = () => {};
	export let continueWithProvider = () => {};

	let authnWithManagedModal = false;
</script>

{#if authnWithManagedModal}
	<LoginRequiredModal
		{contactLoginSuccess}
		{handleContactError}
		{continueWithEthereumExtension}
		{continueWithProvider}
		label={$_('To use a personal account')}
		providers={$data?.managed}
		accountToUse="personal"
		on:close={() => (authnWithManagedModal = false)}
	/>
{/if}

<button
	on:click={() => {
		if (authnWithManaged) {
			authnWithManagedModal = true;
		} else {
			usePersonalAccount = !usePersonalAccount;
		}
	}}
	class="inline-flex items-center space-x-1 text-sm font-medium mb-2"
>
	<span>{usePersonalAccount ? $_('Hide personal account') : $_('Use a personal account')}</span>
	<HelpButton
		ariaLabel="Help"
		content={$_('Providers you can use to log in or recover your Hellō Wallet')}
		placement="bottom"
	/>
</button>
